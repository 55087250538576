<template>
  <div class="container">
    <img
      class="loading"
      v-if="loading"
      src="@/assets/img/common/loading.gif"
      alt=""
    />
    <div class="form-wrap">
      <p class="form-title">绑定手机号</p>
      <el-input
        class="form-item"
        placeholder="请输入手机号"
        v-model="ruleForm.phone"
      >
        <img
          slot="prefix"
          class="icon"
          src="@/assets/img/home/icon1.png"
          alt=""
        />
      </el-input>
      <el-input
        class="form-item"
        placeholder="请输入短信验证码"
        v-model="ruleForm.code"
      >
        <img
          slot="prefix"
          class="icon"
          src="@/assets/img/home/icon2.png"
          alt=""
        />
        <span class="vertical-code" slot="suffix" v-if="isShowSecond"
          >{{ second }}s</span
        >
        <span v-else class="vertical-code" slot="suffix" @click="getPhoneCode"
          >获取验证码</span
        >
      </el-input>
      <div class="flex">
        <button class="btn login-btn">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        phone: "",
        code: "",
      },
      isShowSecond: false,
      second: 60,
    };
  },
  methods: {
    // 获取手机验证码
    getPhoneCode() {
      let _self = this,
        form = _self.ruleForm;
      let regTel = /^1[3456789]\d{9}$/;
      if (!regTel.test(form.phone)) {
        _self.showTip("error", "请输入正确的手机号");
        return;
      }
      _self.axios
        .post(config.url.phoneCode, _self.$qs.stringify({ phone: form.phone }))
        .then((res) => {
          if (res.code == 200) {
            _self.isShowSecond = true;
            let timer = null;
            timer = setInterval(() => {
              _self.second--;
              if (_self.second <= 0) {
                clearInterval(timer);
                _self.second = 60;
                _self.isShowSecond = false;
              }
            }, 1000);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  .loading {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
    img {
      width: 100%;
    }
  }
  .form-wrap {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    .form-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    .form-item {
      margin-bottom: 20px;
      font-size: 14px;
      width: 400px;
      display: block;
    }
    .icon {
      width: 14px;
      height: 16px;
      object-fit: contain;
    }
    ::v-deep .el-input__suffix-inner {
      height: 100% !important;
    }
    .vertical-code {
      color: $color-main;
      cursor: pointer;
      font-size: 14px;
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>